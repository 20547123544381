<template>
  <div class="summary-container">
    <SharedKitButton
      v-if="!isExchangePopupVisible"
      :is-disabled="isButtonDisabled"
      :color-preset="ColorPresets.PRIMARY"
      :size-preset="actionButtonsSize"
      :type-preset="TypePresets.RECTANGLE"
      :text="$t(textBtn)"
      class="summary-container__button"
      @click="emit('sell')"
    />

    <div class="summary-container__text-wrapper">
      <SharedText class="summary-container__text-wrapper__field" :weight="500" :size="buttonFontSize">
        {{ $t('quickSale.selected') }}
        <span class="summary-container__text-wrapper__field__span">
          {{ `${selectedCount}/${allCount}` }}
        </span>
      </SharedText>
      <SharedText class="summary-container__text-wrapper__field" :weight="500" :size="buttonFontSize">
        {{ $t('quickSale.for') }}
        <span class="summary-container__text-wrapper__field__span">
          {{ GlobalUtils.Currency.formatCurrency(saleSum ? saleSum.toFixed(2) : 0) }}
        </span>
      </SharedText>
    </div>

    <SharedKitButton
      v-if="isProfile && isExchangePopupVisible"
      :is-disabled="isButtonDisabled"
      :color-preset="ColorPresets.ACCENT"
      :size-preset="sendButtonSize"
      :type-preset="TypePresets.RECTANGLE"
      :text="t('profile.inventoryActions.take')"
      class="summary-container__button"
      @click="emit('sell')"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ISaleSummaryProps, ISaleSummaryEmits } from './SaleSummary.types';
import { useInventoryStore } from '~/store/inventory/inventory';
import { SizePresets, ColorPresets, TypePresets } from '~/components/SharedKitButton/SharedKitButton.presets';

const { t } = useI18n();

const props = withDefaults(defineProps<ISaleSummaryProps>(), {
  allCount: 0,
  itemType: false,
  saleSum: 0,
  selectedCount: 0,
});

const emit = defineEmits<ISaleSummaryEmits>();

const { selectedCount, saleSum } = toRefs(props);

const inventoryStore = useInventoryStore();

const { isExchangePopupVisible, isShowOnlyType3 } = storeToRefs(inventoryStore);

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const buttonFontSize = computed(() => changeXL(14, 12));
const actionButtonsSize = computed(() => changeXL(SizePresets.L, SizePresets.M));
const sendButtonSize = computed(() => changeXL(SizePresets.M, SizePresets.S));
const route = useRoute();

// TODO: после релиза убрать
const isProfile = computed(() => route.path.includes('/profile') || route.path.includes('SuperSecretProfilePage2'));
const textBtn = computed<string>(() => (isShowOnlyType3.value ? 'take' : 'sell'));
</script>

<style scoped src="./SaleSummary.scss" lang="scss"></style>
